<div class="setup-password-form-container">
  <img src="assets/images/seawatch-light.svg" />
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="setupPassword()">
        <h2>{{'PAGES.SETUP_PASSWORD.TITLE' | translate}}</h2>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="{{'PAGES.SETUP_PASSWORD.PIN' | translate}}" formControlName="pin" required>
          <mat-hint>{{'PAGES.SETUP_PASSWORD.HINT' | translate}}</mat-hint>
          <mat-error>{{'PAGES.SETUP_PASSWORD.ERRORS.REQUIRED_PIN' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input class="hidden" matInput type="text" placeholder="{{'PAGES.SETUP_PASSWORD.PASSWORD' | translate}}" formControlName="password" required>
          <mat-error *ngIf="checkError('password', 'required')">{{'PAGES.SETUP_PASSWORD.ERRORS.REQUIRED_PASSWORD' | translate}}</mat-error>
          <mat-error *ngIf="checkError('password', 'pattern')">{{'PAGES.SETUP_PASSWORD.ERRORS.PATTERN' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input class="hidden" matInput type="text" placeholder="{{'PAGES.SETUP_PASSWORD.REPEAT_PASSWORD' | translate}}" formControlName="repeatPassword" required>
          <mat-error *ngIf="checkError('repeatPassword', 'required')">{{'PAGES.SETUP_PASSWORD.ERRORS.REQUIRED_PASSWORD' | translate}}</mat-error>
          <mat-error *ngIf="checkError('repeatPassword', 'mustMatch')">{{'PAGES.SETUP_PASSWORD.ERRORS.MUST_MATCH' | translate}}</mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!form.valid">{{'BUTTONS.SETUP_PASSWORD' | translate}}</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
